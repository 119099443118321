<template>
  <div>
    <Navbar/>
    <b-container class="text-center ">
      <div
          class="mt-5"
          id="parts-catalog"
          data-key="TWS-3B212936-B6FE-4851-A2F4-8B2009CD5F91"
          data-back-url="/catalogs"
      ></div>
    </b-container>
  </div>

  <!-- <script type="text/javascript" src="https://gui.parts-catalogs.com/v2/parts-catalogs.js"></script> -->
</template>

<script>
import Navbar from "../components/Navbar.vue";
import axios from "axios";

export default {
  components: {Navbar},
  data() {
    return {
      messages: [],
    };
  },
  name: "Catalogs",
  created() {
    axios.post("/stock/messages").then((result) => {
      this.messages = result.data.messages;
    });
    this.$store.commit("SET_SECTION", "KATALOQLAR");
  },
  mounted() {
    let script = document.createElement("script");
    script.setAttribute(
        "src",
        "https://gui.parts-catalogs.com/v2/parts-catalogs.js"
    );
    document.head.appendChild(script);
  },
};
</script>

<style></style>