<template>
  <div>
    <b-navbar toggleable="lg"
              variant="light"
              class="shadow-sm h6 mb-4"
    >
      <b-container>
        <b-navbar-toggle target="nav-collapse"/>
        <b-navbar-brand>
          <b-img
              @click="$router.push({ name: 'Stock' })"
              src="https://tnm-files.fra1.cdn.digitaloceanspaces.com/solo/common-files/solo-logo-black.png"

              height="40px;"
          />
        </b-navbar-brand>
        <b-collapse id="nav-collapse" is-nav>
          <!--      MAIN NAV   -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item :to="{ name: 'Stock' }"
                        :active="$router.currentRoute.name==='Stock'">Məhsullar
            </b-nav-item>
            <!--            <b-nav-item :to="{ name: 'Catalogs' }"-->
            <!--                        :active="$router.currentRoute.name==='Catalogs'">Kataloqlar-->
            <!--            </b-nav-item>-->

            <b-nav-item-dropdown text="Hesabatlar">
              <b-dropdown-item class="border-bottom p-1 mt-n2" :to="{ name: 'BasketOrders' }">
                Sifarişlər
              </b-dropdown-item>
              <b-dropdown-item class="border-bottom p-1" :to="{ name: 'SalesInvoices' }">
                Təsdiqlənmiş Qaimələr
              </b-dropdown-item>
              <b-dropdown-item class="border-bottom p-1" :to="{ name: 'CustomerPayments' }">
                Ödənişlər
              </b-dropdown-item>
              <b-dropdown-item class="py-1 pt-1 mb-n2" :to="{ name: 'DubaiOrders' }">
                Dubay Sifarişləri
              </b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item-dropdown text="Məlumat">

              <div class="px-2 mt-1 mb-2">
                <div class="text-center">
                  <b-badge variant="light" class="my-1 shadow-sm border " style="background-color: #F8C84A">
                    ƏLAQƏ:
                  </b-badge>
                </div>
                <div style="width: 220px;">
                  <b-badge variant="light" class="my-1 shadow-sm border">Mallarla bağlı:
                  </b-badge>
                  <b-badge variant="light" class="my-1 float-right shadow-sm border"> 070 600 27 56
                  </b-badge>
                  <b-badge variant="light" class="my-1 shadow-sm border">Mühasibat:
                  </b-badge>
                  <b-badge variant="light" class="my-1 float-right shadow-sm border"> 055 600 27 56
                  </b-badge>

                </div>

              </div>

              <b-dropdown-item class="border-bottom border-top p-1">
                <b-icon-person-circle/>
                {{ $store.state.username }}
              </b-dropdown-item>
              <b-dropdown-item class="p-1 mb-n2" @click="logout">
                <span class="font-weight-bold text-danger">Çıxış</span>
              </b-dropdown-item>
            </b-nav-item-dropdown>


          </b-navbar-nav>
          <!--        LOGOUT NAV   -->

        </b-collapse>
      </b-container>
    </b-navbar>


  </div>
</template>

<script>

export default {
  name: "Nav",
  methods: {
    logout() {
      this.$store.dispatch("LOGOUT");
    },
  },
};
</script>